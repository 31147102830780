<template>
  <div>
    <div v-if="type == 1">
      <campus @change="changeCampus" />
    </div>
    <van-notice-bar left-icon="volume-o" :text="$t('notice.preview_model')" v-if="$route.name == 'calendarView'" />
    <div v-if="yearList.length != 0">
      <van-row type="flex" justify="end" class="lang">
        <switch-lang color="#377ED7" />
      </van-row>
      <van-tabs v-if="type == 1 && yearList.length > 1" v-model="active" color="#4D88D2" title-active-color="#323233"
        title-inactive-color="#969799" @click="getData" class="navList">
        <van-tab v-for="(year, index) in yearList" :title="year.year" :key="index" :name="year.yid.toString()">
        </van-tab>
      </van-tabs>
      <div v-if="calendar_days_copy.length != 0">
        <van-sticky z-index="9999" v-if="day_event_type.length != 0">
          <div class="filterTagList filterEvent" ref="filterTagList">
            <span @click="filterEvent('all')" :class="filterType == 'all' ? 'filterActive' : ''">{{ $t("global.All")
              }}</span>
            <span v-for="(list, index) in day_event_type" :key="index" @click="filterEvent(list.type)"
              :class="filterType == list.type ? 'filterActive' : ''">{{ list.name }}</span>
          </div>
        </van-sticky>
        <div class="calendar" v-if="filterType == 'all'">
          <div class="page1">
            <div class="logo">
              <img src="http://m2.files.ivykids.cn/cloud01-file-8025768FpUhKfisfbTkjXJTpyoMa0OpCrw5.png" alt="" />
            </div>
            <div class="title">
              <div class="year fontWeight6">{{ showYear() }}</div>
              <div class="calendarText">{{ $t("calendar.schoolcalendar") }}</div>
            </div>
            <div class="font14 total">
              <div class="flex">
                <div class="flex1 color3">
                  <div>{{ $t("calendar.Total_Days") }}</div>
                </div>
                <div class="flex1 text-right">
                  <span><span class="num mr-5 fontWeight6 color6">{{ semesterData.all_total }}</span>{{ $t("lunch.Day")
                    }}</span>
                </div>
              </div>
              <div class="flex">
                <div class="flex1 color3">
                  <div>{{ $t("calendar.1st") }}</div>
                </div>
                <div class="flex1 text-right">
                  <span><span class="num mr-5 fontWeight6 color6">{{ semesterData.first_total }}</span>{{
                    $t("lunch.Day") }}</span>
                </div>
              </div>
              <div class="flex">
                <div class="flex1 color3">
                  <div>{{ $t("calendar.2nd") }}</div>
                </div>
                <div class="flex1 text-right">
                  <span><span class="num mr-5 fontWeight6 color6">{{ semesterData.second_total }}</span>{{
                    $t("lunch.Day") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="monthPage">
            <div class="pageList" v-for="(list, index) in calendar_days" :key="index" :id="list.year_month">
              <div class="flex pageList_month">
                <div class="flex1 flex align-center">
                  <div class="font38 pr-5 fontWeight6">{{ list.month }}</div>
                  <div class="font12">
                    <div>月</div>
                    <div>{{ list.month_en }}</div>
                  </div>
                </div>
                <div class="fontWeight6">{{ list.year }}</div>
              </div>
              <div v-if="calendar_full_month[list.year_month]">
                <div v-for="(full, ix) in calendar_full_month[list.year_month]" :key="ix" class="allMonth">
                  <span>——</span> {{ full }} <span>——</span>
                </div>
              </div>
              <div class="days">
                <div class="week flex text-center fontWeight6">
                  <div class="flex1">{{ $t("lunch.Sunday") }}</div>
                  <div class="flex1">{{ $t("lunch.Monday") }}</div>
                  <div class="flex1">{{ $t("lunch.Tuesday") }}</div>
                  <div class="flex1">{{ $t("lunch.Wednesday") }}</div>
                  <div class="flex1">{{ $t("lunch.Thursday") }}</div>
                  <div class="flex1">{{ $t("lunch.Friday") }}</div>
                  <div class="flex1">{{ $t("lunch.Saturday") }}</div>
                </div>
                <div class="week flex text-center" v-for="(item, index) in list.week" :key="index">
                  <div v-for="(_item, idx) in item" :key="idx" class="flex1 font16">
                    <span v-if="showEvent(list, _item)" class="doteventColor"></span>
                    <span v-if="showWeek(list, _item)" class="calendarDays weekColor"> </span>
                    <span v-if="showHoliday(list, _item)">
                      <span v-if="showHolidayType(list, _item) == 1" class="calendarDays holidayColor"> </span>
                      <span v-if="showHolidayType(list, _item) == 2">
                        <template v-if="firstDay(list, _item) == 1 || firstDay(list, _item) == 2">
                          <div class="multipleDays" v-if="firstDay(list, _item) == 1">
                            <div class="startbgColor"
                              :class="showColor(list, _item, 'start') == 10 ? 'multipleholidayColor' : ''"></div>
                            <div class=""
                              :class="showColor(list, _item, 'start') == 10 ? 'startMultiple holidayColor' : ''"></div>
                          </div>
                          <div class="multipleDays" v-if="firstDay(list, _item) == 2">
                            <div class="endbgColor"
                              :class="showColor(list, _item, 'end') == 10 ? 'multipleholidayColor' : ''"></div>
                            <div class=""
                              :class="showColor(list, _item, 'end') == 10 ? 'startMultiple holidayColor' : ''"></div>
                          </div>
                        </template>
                        <div v-else :class="multiple(list, _item) == 10 ? 'multipleholidayColor' : ''"
                          class="multipleDays"></div>
                      </span>
                    </span>
                    <span class="daysList" v-if="showHolidayType(list, _item) == 2">
                      <template v-if="firstDay(list, _item) == 1 || firstDay(list, _item) == 2">
                        <span v-if="firstDay(list, _item) == 1"
                          :class="showColor(list, _item, 'start') == 10 ? '' : ''"> {{ _item }}</span>
                        <span v-if="firstDay(list, _item) == 2" :class="showColor(list, _item, 'end') == 10 ? '' : ''">
                          {{ _item }}</span>
                      </template>
                      <span v-else :class="multiple(list, _item) == 10 ? 'holidayColorText' : ''"> {{ _item }}</span>
                    </span>

                    <span class="daysList" v-else> {{ _item }}</span>
                  </div>
                </div>
              </div>
              <div class="holiday" v-if="holidayList[list.year_month]">
                <div>
                  <img src="http://m2.files.ivykids.cn/cloud01-file-8025768FgloRfFKMP8reql6vPdTITwTg95J.png"
                    class="flpushpin" alt="" />
                  <img src="http://m2.files.ivykids.cn/cloud01-file-8025768FgloRfFKMP8reql6vPdTITwTg95J.png"
                    class="frpushpin" alt="" />
                </div>
                <div v-for="(days, i) in holidayList[list.year_month]" :key="i" class="holidayLen">
                  <div class="flex marginTop">
                    <div class="daysDetails" v-if="Object.keys(days.days).length == 1">
                      <div class="startDays"
                        :class="days.event == 10 ? 'holidayColor' : days.event == 20 ? 'eventColor' : 'weekColor'">
                        {{ substr(days) }}
                      </div>
                    </div>
                    <div class="daysDetails" v-else>
                      <div class="moreDays"
                        :class="days.event == 10 ? 'holidayColor' : days.event == 20 ? 'eventColor' : 'weekColor'">
                        <div>
                          <div class="pt-5">{{ substr(days) }}</div>
                          <div class="font12 lineDay">|</div>
                          <div class="pb-5">{{ substrEnd(days) }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="flex1 ">
                      <div :class="Object.keys(days.days).length == 1 ? '' : 'moreDaysFlex'">
                        <div>
                          <div class="color3 font14 " :class="Object.keys(days.days).length == 1 ? 'pt-5' : ''">{{ days.title }}</div>
                          <div class="pt-3 color6 font12" v-if="days.memo != ''">{{ days.memo }}</div>
                          <div class="pt-3" v-if="days.day_type == 20">
                            <van-tag color="rgba(77, 136, 210, 0.10)" text-color="#4D88D2">Half
                              Day</van-tag>
                          </div>
                          <div class="pt-3" v-if="days.time_str[0] != '' && days.time_str[0] != null">
                            <van-tag color="#EBEDF0" text-color="#646566">{{
                              days.time_str[0] }} — {{ days.time_str[1] }}</van-tag>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-15">
            <div class="monthLine" v-for="(list, key, index) in filterEventList" :key="index">
              <div class="line">
                <div class="lineLeft" v-if="index + 1 < Object.keys(filterEventList).length"></div>
                <div class="lineBorder">
                  <div class="lineLong"></div>
                </div>
              </div>
              <div class="flex1 mb-12">
                <div class="mb-12">
                  <span class="font16 color3 fontWeight6 mr-10">{{ showMonth(key) }}</span>
                  <span><van-tag round text-color="#64D2CC" size="medium" color="rgba(100, 210, 204, 0.10)" class="mr-5"
                      :key="ins" v-for="(tag, ins) in showTagMonth(list)">{{ tag.title }}</van-tag></span>
                </div>
                <div v-for="(days, i) in list" :key="i" class="">
                  <div class="flex mb-12 pt-10 eventLen" v-if="days.event_type == filterType">
                    <div class="daysDetails" v-if="Object.keys(days.days).length == 1">
                      <div class="startDays"
                        :class="days.event == 10 ? 'holidayColor' : days.event == 20 ? 'eventColor' : 'weekColor'">
                        {{ substr(days) }}
                      </div>
                    </div>
                    <div class="daysDetails" v-else>
                      <div class="moreDays"
                        :class="days.event == 10 ? 'holidayColor' : days.event == 20 ? 'eventColor' : 'weekColor'">
                        <div>
                          <div class="pt-5">
                            {{ substr(days) }}
                          </div>
                          <div class="font12 lineDay">|</div>
                          <div class="pb-5">
                            {{ substrEnd(days) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex1">
                      <div :class="Object.keys(days.days).length == 1 ? '' : 'moreDaysFlex'">
                        <div class="color6 font13 " :class="Object.keys(days.days).length == 1 ? 'pt-5' : ''">
                          <!-- <span v-if="days.full_month">（整月事件）</span> -->
                          <div class="color3 font14">{{ days.title }}</div>
                          <div class="pt-3 color6 font12" v-if="days.memo != ''">{{ days.memo }}</div>
                          <div class="pt-3" v-if="days.day_type == 20">
                            <van-tag color="rgba(77, 136, 210, 0.10)" text-color="#4D88D2">Half
                              Day</van-tag>
                          </div>
                          <div class="pt-3" v-if="days.time_str[0] != '' && days.time_str[0] != null">
                            <van-tag color="#EBEDF0" text-color="#646566">{{ days.time_str[0] }} — {{
                              days.time_str[1]
                            }}</van-tag>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- v-if="schoolid == 'BJ_QFF' && active==156" -->
        <div>
          <div class="color6 text-center mb-20 mt-20 font12" v-if="semesterData.remark != ''">
            <div class="pb-10">{{ semesterData.remark }}</div>
          </div>
        </div>
        <div class="fixed" v-if="pdfUrl != ''">
          <van-button type="default" class="btn" :url="pdfUrl">{{ $t("calendar.pdf_version") }}</van-button>
        </div>
      </div>
    </div>
    <van-empty v-else-if="showCalendar" :description="$t('calendar.no_data')" />
  </div>
</template>

<script>
import Vue from "vue";
import { Image as VanImage, Empty, Tab, Tabs } from "vant";
import Campus from "@/components/Campus";
import storage from "store";
import { APP_LANGUAGE } from "@/store/mutation-types";
import { getCalendar, viewCalendar } from "@/api/Calendar";
import SwitchLang from "@/components/SwitchLang";

Vue.use(VanImage);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  name: "Notice",
  components: {
    campus: Campus,
    "switch-lang": SwitchLang,
  },
  data() {
    return {
      calendar_days: [],
      holidayList: [],
      semesterData: {},
      yearList: [],
      active: "",
      pdfUrl: "",
      type: 1,
      showCalendar: false,
      day_event_type: [],
      fliterEventType: [],
      calendar_days_copy: [],
      filterType: "all",
      filterEventList: {},
      calendar_full_month: {},
    };
  },
  created() {
    this.currentLang = storage.get(APP_LANGUAGE) || "zh";
    if (this.$route.name == "calendarView") {
      this.type = 2;
      this.schoolid = this.$route.params.school_id;
      this.active = this.$route.params.yid;
      this.getViewData();
    }
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  methods: {
    changeCampus(val) {
      this.schoolid = val;
      this.active = "";
      this.getData();
    },
    getViewData() {
      this.showCalendar = false;
      viewCalendar(this.schoolid, this.active).then((res) => {
        this.calendar_days_copy = res.data.calendar_days;
        this.calendar_days = res.data.calendar_days;
        this.holidayList = res.data.calendar_day_list_by_month;
        this.semesterData = res.data.calendar_yearly_data;
        this.yearList = res.data.year_list;
        this.calendar_full_month = res.data.calendar_full_month;
        this.pdfUrl = res.data.calendar_yearly_data.pdf;
        this.showCalendar = true;
        this.active = res.data.select_yid;
        this.day_event_type = res.data.day_event_type_list;
        this.filterType = "all";
        this.$nextTick(function () {
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const month = currentMonth < 10 ? "0" + currentMonth : currentMonth;
          if (document.getElementById(currentYear + month)) {
            document.getElementById(currentYear + month).scrollIntoView({ block: "start" });
            var element = this.$refs.filterTagList;
            var height = element.offsetHeight;
            window.scrollTo(window.scrollX, window.scrollY - height);
          }
        });
      });
    },
    getData() {
      this.showCalendar = false;
      getCalendar({ school_id: this.schoolid, yid: this.active }).then((res) => {
        this.calendar_days = res.data.calendar_days;
        this.calendar_days_copy = res.data.calendar_days;
        this.holidayList = res.data.calendar_day_list_by_month;
        this.semesterData = res.data.calendar_yearly_data;
        this.calendar_full_month = res.data.calendar_full_month;
        this.yearList = res.data.year_list;
        this.pdfUrl = res.data.calendar_yearly_data.pdf;
        this.showCalendar = true;
        this.active = res.data.select_yid;
        this.day_event_type = res.data.day_event_type_list;
        this.filterType = "all";
        this.$nextTick(function () {
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const month = currentMonth < 10 ? "0" + currentMonth : currentMonth;
          if (document.getElementById(currentYear + month)) {
            document.getElementById(currentYear + month).scrollIntoView({ block: "start" });
            var element = this.$refs.filterTagList;
            var height = element.offsetHeight;
            window.scrollTo(window.scrollX, window.scrollY - height);
          }
        });
      });
    },
    showYear() {
      let year = "";
      this.yearList.forEach((item) => {
        if (item.yid == this.active) {
          year = item.year;
        }
      });
      return year;
    },
    substr(item) {
      return (Object.values(item.days)[0] + "").substring(6, 8);
    },
    substrEnd(item) {
      let len = Object.values(item.days).length;
      let days = (Object.values(item.days)[len - 1] + "").substring(6, 8);
      return days;
    },
    showHoliday(list, day) {
      var holiday = false;
      var days = "";
      var daysLen = "";
      if (this.holidayList[list.year_month]) {
        var lens = this.holidayList[list.year_month].filter((item) => item.event == 10);
        lens.forEach((item) => {
          if (Object.keys(item.days).length == 1) {
            days = item.start_date.substring(8, 10);
          } else {
            daysLen = Object.keys(item.days).filter((item) => Object.keys(item));
          }
          if (parseInt(days) == day || daysLen.indexOf(day + "") != -1) {
            holiday = true;
          }
        });
        return holiday;
      }
    },
    showHolidayType(list, day) {
      var holiday = "";
      var days = "";
      var daysLen = "";
      if (this.holidayList[list.year_month]) {
        var lens = this.holidayList[list.year_month].filter((item) => item.event == 10);
        lens.forEach((item) => {
          if (Object.keys(item.days).length == 1) {
            days = item.start_date.substring(8, 10);
          } else {
            daysLen = Object.keys(item.days).filter((item) => Object.keys(item));
          }
          if (parseInt(days) == day) {
            holiday = 1;
          } else if (daysLen.indexOf(day + "") != -1) {
            holiday = 2;
          }
        });
        return holiday;
      }
    },
    showWeek(list, day) {
      var week = false;
      var days = "";
      var daysLen = "";
      if (this.holidayList[list.year_month]) {
        var lens = this.holidayList[list.year_month].filter((item) => item.event == 30);
        lens.forEach((item) => {
          if (Object.keys(item.days).length == 1) {
            days = item.start_date.substring(8, 10);
          } else {
            daysLen = Object.keys(item.days).filter((item) => Object.keys(item));
          }
          if (parseInt(days) == day || daysLen.indexOf(day + "") != -1) {
            week = true;
          }
        });
        return week;
      }
    },
    showEvent(list, day) {
      var event = false;
      var days = "";
      var daysLen = "";
      if (this.holidayList[list.year_month]) {
        var lens = this.holidayList[list.year_month].filter((item) => item.event == 20 && !item.full_month);
        lens.forEach((item) => {
          if (Object.keys(item.days).length == 1) {
            days = item.start_date.substring(8, 10);
          } else {
            daysLen = Object.keys(item.days).filter((item) => Object.keys(item));
          }
          if (!item.full_month) {
            if (parseInt(days) == day || daysLen.indexOf(day + "") != -1) {
              event = true;
            }
          } else {
            event = false;
          }
        });
        return event;
      }
    },
    showColor(list, day, status) {
      var type = "";
      let month = list.month;
      if (this.holidayList[list.year_month]) {
        this.holidayList[list.year_month].forEach((item) => {
          if (status == "start") {
            let days = (Object.values(item.days)[0] + "").substring(6, 8);
            let startMonth = (Object.values(item.days)[0] + "").substring(4, 6);
            if (item.event == 10 && Object.values(item.days).length != 1 && parseInt(startMonth) + "" + parseInt(days) == month + "" + day) {
              type = item.event;
            }
          } else {
            let len = Object.values(item.days).length;
            let days = (Object.values(item.days)[len - 1] + "").substring(6, 8);
            let startMonth = (Object.values(item.days)[len - 1] + "").substring(4, 6);
            if (item.event == 10 && Object.values(item.days).length != 1 && parseInt(startMonth) + "" + parseInt(days) == month + "" + day) {
              type = item.event;
            }
          }
        });
      }
      return type;
    },
    firstDay(list, day) {
      var lastDay = 0;
      if (this.holidayList[list.year_month]) {
        this.holidayList[list.year_month].forEach((item) => {
          if (Object.keys(item.days).length != 1) {
            for (var key in item.days) {
              let List = item.days[key] + "";
              let showDay = List.substring(6, 8);
              let first = (Object.values(item.days)[0] + "").substring(6, 8);
              let len = Object.values(item.days).length;
              let last = (Object.values(item.days)[len - 1] + "").substring(6, 8);
              if (item.event == 10 && showDay == day && parseInt(showDay) == parseInt(first)) {
                lastDay = 1;
              }
              if (item.event == 10 && showDay == day && parseInt(showDay) == parseInt(last)) {
                lastDay = 2;
              }
            }
          }
        });
      }
      return lastDay;
    },
    multiple(list, day) {
      let month = list.month;
      var multipleDays = "";
      if (this.holidayList[list.year_month]) {
        this.holidayList[list.year_month].forEach((item) => {
          if (Object.keys(item.days).length != 1) {
            for (var key in item.days) {
              let List = item.days[key] + "";
              let showDay = List.substring(6, 8);
              let showMonth = List.substring(4, 6);

              if (day != null) {
                if (item.event == 10 && Object.values(item.days).length != 1 && month + "" + day == parseInt(showMonth) + "" + parseInt(showDay)) {
                  multipleDays = item.event;
                }
              }
            }
          }
        });
      }

      return multipleDays;
    },
    filterEvent(type) {
      this.filterType = type;
      this.filterEventList = {};
      for (var key in this.holidayList) {
        for (let i = 0; i < this.holidayList[key].length; i++) {
          if (this.holidayList[key][i].event_type == type) {
            this.filterEventList[key] = this.holidayList[key];
          }
        }
      }
      window.scrollTo(0, 0);
    },
    showMonth(numStr) {
      let year = numStr.substring(0, 4);
      let month = numStr.substring(4);
      month = month.padStart(2, "0");
      let formattedDate = year + "." + month;
      return formattedDate;
    },
    showTagMonth(list) {
      let dataList = list.filter((a) => {
        return a.event_type == this.filterType && a.full_month;
      });
      return dataList;
    },
  },
};
</script>
<style lang="less">
.navList {
  .van-tabs__wrap {
    .van-tabs__nav {
      background: none;
    }
  }
}
</style>
<style lang="less" scoped>
.fixed {
  position: fixed;
  bottom: 45px;
  right: 16px;
  z-index: 9;

  .btn {
    border-radius: 100px;
    height: 32px;
  }
}

.allMonth {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;

  span {
    color: rgba(255, 255, 255, 0.8);
  }
}

.text-right {
  text-align: right;
}

.align-center {
  align-items: center;
}

.font38 {
  font-size: 38px;
}

.daysColor {
  color: #9aaaf0;
}

.fontWeight6 {
  font-weight: 600;
}

.holidayColor {
  background: #f9c340;
  color: #fff;
}

.doteventColor {
  width: 6px;
  height: 6px;
  background: #64d2cc;
  border-radius: 50%;
  background: #64d2cc;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -3px;
}

.eventColor {
  color: #fff;
  background: #64d2cc;
}

.weekColor {
  color: #fff;
  background: #dc4949;
}

.holidayLine {
  border: 1px solid #f9c340;
}

.eventLine {
  border: 1px solid #64d2cc;
}

.weekLine {
  border: 1px solid #dc4949;
}

.multipleeventColor {
  color: #64d2cc !important;

  background: #d8fefc !important;
}

.multipleweekColor {
  background: #ffc7c7;
}

.multipleholidayColor {
  background: #fff7e5;
}

.holidayColorText {
  color: #f9c340;
}

.startMultiple {
  border-radius: 50%;
  width: 28px;
  position: absolute;
  left: 50%;
  z-index: 99;
  height: 28px;
  margin-left: -14px;
}

.startbgColor {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 28px;
  z-index: 9;
}

.endbgColor {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 28px;
  z-index: 9;
}

.phoenix {
  text-align: right;
  font-size: 14px;
  padding-right: 20px;
  padding-top: 16px;

  a {
    color: #4d88d2;
  }
}

.daysList {
  z-index: 999;
  width: 28px;
  height: 28px;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  line-height: 28px;
  position: absolute;
  left: 50%;
  top: 0px;
  z-index: 99;
  margin-left: -14px;
  font-size: 16px;
}

.lang {
  margin: 8px 16px 0;
}

.filterTagList::-webkit-scrollbar {
  display: none;
}

.filterTagList {
  overflow-x: auto;
  white-space: nowrap;
  padding: 16px 16px 0;
  background: #fff;
  height: 34px;

  .filterTag {
    font-size: 13px;
    font-weight: 400;
    color: #323233;
    background: #f7f8fa;
    border-radius: 50px;
    margin-right: 10px;
    padding: 6px 12px;
    display: inline-block;
  }

  .primary {
    color: #fff;
    background: #4d88d2;
  }
}

.filterEvent {
  .filterActive {
    color: #fff;
    background: #4d88d2;
  }

  span {
    padding: 6px 16px;
    background: #f7f8fa;
    font-size: 13px;
    color: #3c3c3c;
    border-radius: 16px;
    margin-right: 8px;
  }
}

.monthLine {
  display: flex;
  padding: 0 16px;

  .line {
    margin-right: 8px;
    position: relative;
    margin-top: 5px;

    .lineLeft {
      width: 1px;
      height: 100%;
      background: #dcdee0;
      position: absolute;
      left: 5px;
      top: 5px;
    }

    .lineBorder {
      width: 10px;
      height: 10px;
      background: #ffffff;
      border: 1px solid #dcdee0;
      border-radius: 50%;
      position: relative;

      .lineLong {
        width: 6px;
        height: 6px;
        background: #4d88d2;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        left: 2px;
        top: 2px;
      }
    }
  }

  .flex1 {
    flex: 1;

    .eventLen {
      padding: 12px 16px;
      border-radius: 8px;
      background: #f7f8fa;
    }
  }
}

.daysDetails {
  margin-right: 12px;

  .startDays {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }

  .moreDays {
    height: 100%;
    text-align: center;
    color: #fff;
    border-radius: 13px;
    width: 28px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    div {
      line-height: 20px
    }

    .lineDay {
      opacity: 0.7;
      line-height: 14px;
    }
  }

  .line {
    height: 10px;
    width: 0px;
    margin: 0 auto;
  }

  .endDays {
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-left: 3px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 15px;
  }
}

.moreDaysFlex {
  display: flex;
  /* 使用Flex布局 */
  align-items: center;
  /* 垂直居中 */
  height: 100%;
}

.calendar {
  padding: 16px;

  .page1 {
    background: url("http://m2.files.ivykids.cn/cloud01-file-8025768Fii6y1sRaSy5faCNgtP7bM7w743J.png");
    background-size: 100% 100%;

    // height: 453px;
    .logo {
      text-align: center;
      padding-top: 21px;

      img {
        width: 184px;
      }
    }

    .title {
      color: #fff;
      text-align: center;
      padding-top: 50px;

      .year {
        font-size: 30px;
      }

      .calendarText {
        font-size: 17px;
        padding-top: 5px;
      }
    }

    .total {
      padding: 30px 32px;
      color: #3c3c3c;
      width: 80%;
      margin: 0 auto;

      .flex {
        align-items: center;
        margin-top: 10px;

        .num {
          color: #7183e2;
          font-size: 30px;
        }
      }
    }
  }

  .monthPage {
    .pageList {
      background: url("https://m2.files.ivykids.cn/cloud01-file-8025768FpWj0ET0150x5z8cUIbEGjG5e-WF.png");
      background-size: 100% 100%;
      margin-top: 16px;
      padding: 16px;
      border-radius: 18px;

      .pageList_month {
        color: #fff;
        font-size: 25px;
        align-items: center;
      }
    }

    .days {
      color: #fff;
      margin-top: 16px;

      .flex1 {
        height: 40px;
        line-height: 40px;
        position: relative;
      }

      .oneDays {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      .calendarDays {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        line-height: 28px;
        position: absolute;
        left: 50%;
        top: 0px;
        z-index: 99;
        margin-left: -14px;
        font-size: 16px;
      }

      .multipleDays {
        height: 28px;
        height: 28px;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        font-size: 16px;
        line-height: 28px;
        position: relative;
        overflow: hidden;
      }
    }

    .holiday {
      background: #fff;
      border-radius: 8px;
      position: relative;
      padding: 16px;
      margin-top: 20px;

      .flpushpin {
        width: 11px;
        position: absolute;
        left: 16px;
        top: -1px;
      }

      .frpushpin {
        width: 11px;
        position: absolute;
        right: 16px;
        top: -1px;
      }

      .holidayLen {
        .marginTop{
          margin:16px 0;
        }
      }
    }
  }
}
</style>
