<template>
  <div>
    <div v-if="initData.childInfo">
      <van-notice-bar left-icon="volume-o" :text="$t('notice.preview_model')" v-if="$route.name == 'previewWithdrawalBill'" />
      <div class="childDetail">
        <van-row type="flex" justify="end" class="mb-20" v-if="$route.name == 'previewWithdrawalBill'">
          <switch-lang color="#377ED7" />
        </van-row>
        <div class="flex align_items">
          <div><img :src="initData.childInfo.avatar" alt="" class="img44" /></div>
          <div class="flex1 ml-12">
            <div class="color3 font14 fontBold">{{ initData.childInfo.name }}</div>
            <div class="color6 font12">{{ initData.childInfo.className }}</div>
          </div>
        </div>
      </div>
      <div class="mt-16" style="margin: 16px" v-if="initData.status == -1"><van-notice-bar left-icon="info-o" wrapable text="等待校园处理" /></div>
      <div class="applicationInfo" v-else>
        <img src="../assets/dropoutConfirm.png" v-if="initData.status == 1" alt="" class="dropoutConfirm" />
        <div>
          <div class="send_memo" v-if="initData.financeInfo.send_memo != ''">
            <span v-html="initData.financeInfo.send_memo.replace(/\n/g, '<br>')"></span>
          </div>
          <div :class="index != 0 ? 'pt-24' : ''" v-for="(list, key, index) in viewTable" :key="index">
            <div v-if="key === 'enrollment'">
              <div class="subTitle">
                <img src="../assets/withdrawalEn.png" alt="" />
                <span>{{ initData.nodesTitle[key] }}</span>
              </div>
              <div class="mt-16 font14 color3">{{ list.balance == 0 ? "无学籍" : "有学籍" }}</div>
              <div class="color9 font12 mt-8" v-if="list.prompt != ''">{{ list.prompt }}</div>
            </div>
            <div v-else-if="key === 'insurance'">
              <div class="subTitle">
                <img src="../assets/withdrawalIn.png" alt="" />
                <span>{{ initData.nodesTitle[key] }}</span>
              </div>
              <div class="mt-16 font14 color3">{{ list.balance == 0 ? "无社保" : "有社保" }}</div>
            </div>
          </div>
          <div class="pt-24">
            <div class="subTitle">
              <img src="../assets/withdrawalBill.png" alt="" />
              <span>账单明细</span>
            </div>
            <div class="refundInfo">
              <div class="flex1">
                <div class="mt-15">
                  <span class="refund" v-if="initData.financeInfo.total_amount == 0">退费金额</span>
                  <span class="refund" v-else>{{ Math.sign(initData.financeInfo.total_amount) == 1 ? "退费金额" : "您需支付" }}</span>
                </div>
                <div class="amount">￥{{ (Math.abs(initData.financeInfo.total_amount) * 1) / 1 }}</div>
              </div>
              <img src="../assets/refunds.png" alt="" />
            </div>

            <div class="pt-24" v-for="(list, key, index) in amountTable" :key="index">
              <div class="titleAmount">
                {{ initData.nodesTitle[key] }}
              </div>
              <div v-if="key === 'it_equ'" class="flex refundItems">
                <div class="flex1 font14 color6">{{ list.balance == 1 ? "已归还" : list.balance == 2 ? "存在设备损坏" : list.balance == 3 ? "未归还" : "" }}</div>
                <div class="font14 redColor" v-if="(list.balance == 2 || list.balance == 3) && list.balance_amount != ''">赔偿 ￥{{ list.balance_amount }}</div>
              </div>
              <template v-else>
                <template v-if="list.goods.length">
                  <div v-for="(item, idx) in list.goods" :key="idx" :class="idx + 1 < list.goods.length ? 'bto' : ''">
                    <div class="flex refundItems">
                      <div class="flex1 font14 color6">
                        <div class="color3 font14">{{ item.name }}</div>
                        <div class="color9 font12 mt-8" v-if="item.memo != ''">{{ $t("notice.memo") }}：{{ item.memo }}</div>
                      </div>
                      <div class="font14 redColor font14" v-if="!Array.isArray(list.balance)">赔偿 ￥{{ item.amount }}</div>
                      <div class="font14 font14" v-else >
                        <span v-if="item.balance == '1'" class="redColor">赔偿 ￥{{ item.amount }}</span>
                        <span v-if="item.balance == '0'" :class="item.amount == 0 ? '' : 'colorGreen'">{{item.amount == 0 ? '' : '退费'}} ￥{{ item.amount }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else class="mt-16 font14 colorGreen">已全部归还</div>
              </template>
            </div>
          </div>
          <div class="flex mt-24" v-if="initData.status != 1 && $route.name != 'previewWithdrawalBill'">
            <div class="flex1 mr-5"><van-button type="default" :disabled="initData.status == 2 ? true : false" block round @click="haveDoubts">有异议，我要反馈</van-button></div>
            <div class="flex1 ml-5"><van-button type="primary" block round color="#4D88D2" @click="signShowDailog()">无异议，签字确认</van-button></div>
          </div>
          <div v-if="initData.status == 1">
            <img :src="initData.financeInfo.sign_url" alt="" style="width: 100%;background: #fafafa;margin-top: 24px;" />
          </div>
          <div class="flex tips mt-16" v-if="initData.status == 2">
            <van-icon name="info-o" size="16" class="icon colorYellow" />
            <div class="flex1 ml-10">
              <div class="colorYellow font14">账单有异议反馈成功，老师将与您取得联系</div>
              <div class="coloe3 mt-12 font12">{{ initData.financeInfo.feedback_memo }}</div>
              <div class="color6 font12 mt-8">
                —
                {{ initData.financeInfo.feedback_parent == "m" ? $t("coll.Mother") : $t("coll.Father") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <van-dialog v-model="queryShow" title="" show-cancel-button :before-close="beforeClose">
        <slot name="title">
          <div class="text-center fontBold pt-20">
            <div>反馈</div>
          </div>
        </slot>
        <div class="p16">
          <van-field v-model="message" :placeholder="$t('global.please_input')" class="bgGray mt-10" type="textarea" rows="3" />
          <div class="font12 color6 mt-12 mb-15">请简要描述反馈内容，老师将与您取得联系</div>
        </div>
      </van-dialog>
    </div>
    <div class="text-center pt-50 mt-50" v-if="initData.status == 99">
      <img class="img" src="../assets/completed.png" alt="" style="width: 200px" />
      <div class="font17 color3 fontBold mt-12">“退学确认函” 已完成</div>
    </div>
    <div class="signCon" v-if="signShow" @touchmove.prevent>
      <sign @click="baseImg"></sign>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast, Dialog, Tag, RadioGroup, Radio, DatetimePicker, ActionSheet, Popup } from "vant";
import {} from "vant";
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Popup);
import { childInfo, feedback, parentConfirm, withdrawalFinance } from "@/api/withdrawal";
import sign from "@/components/steps/sign";
import SwitchLang from "@/components/SwitchLang";

export default {
  name: "PTC",
  components: {
    sign: sign,
    "switch-lang": SwitchLang,
  },
  data() {
    return {
      appid: "",
      initData: {},
      currentDateCopy: "",
      currentDate: "",
      showDate: false,
      queryShow: false,
      message: "",
      withdrawal_where: "",
      withdrawal_where_memo: "",
      withdrawal_reason: "",
      withdrawal_reason_memo: "",
      withdrawal_unsatisfied: "",
      withdrawal_recommendations: "",
      withdrawal_contact_user: "",
      withdrawal_contact_name: "",
      withdrawal_contact_phone: "",
      base64: "",
      signShow: false,
      sign_pdf: "",
      sign_url: "",
      viewTable: {},
      amountTable: {},
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  created() {
    this.appid = this.$route.params.id;
    if (this.$route.name == "previewWithdrawalBill") {
      withdrawalFinance(this.appid, { token: this.$route.query.token }).then((res) => {
        this.initData = res.data;
        if (this.initData.financeInfo.items) {
          for (let key in this.initData.financeInfo.items) {
            if (key === "enrollment" || key === "insurance") {
              this.viewTable[key] = this.initData.financeInfo.items[key];
            } else {
              this.amountTable[key] = this.initData.financeInfo.items[key];
            }
          }
        }
      });
    } else {
      this.getInit();
    }
  },
  watch: {},
  methods: {
    getInit() {
      childInfo(this.appid).then((res) => {
        this.initData = res.data;
        if (this.initData.financeInfo.items) {
          for (let key in this.initData.financeInfo.items) {
            if (key === "enrollment" || key === "insurance") {
              this.viewTable[key] = this.initData.financeInfo.items[key];
            } else {
              this.amountTable[key] = this.initData.financeInfo.items[key];
            }
          }
        }
      });
    },
    haveDoubts() {
      this.queryShow = true;
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        feedback({ app_id: this.appid, memo: this.message }).then((res) => {
          if (res.code == 0) {
            Toast(res.msg);
            this.getInit();
          }
          this.queryShow = false;
          done();
        });
      } else if (action === "cancel") {
        done(); //关闭
      }
    },
    signShowDailog() {
      document.body.style.overflow = "hidden";
      this.signShow = true;
    },
    baseImg(data) {
      const that = this;
      if (data != "cancel") {
        this.imgData = data;
        var pic = data.substring(data.indexOf(",") + 1);
        var url = "https://upload-z1.qiniup.com/putb64/-1";
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
          // toast.clear()
          if (xhr.readyState == 4) {
            var resJson = JSON.parse(xhr.responseText);
            if (resJson.code == 0) {
              Vue.set(that, "sign_url", resJson.data.url);
              Vue.set(that, "sign_pdf", resJson.data.key);
              that.saveData();
            } else {
              Toast(resJson.msg);
            }
          }
        };
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.setRequestHeader("Authorization", "UpToken " + that.initData.financeInfo.sign_token);
        xhr.send(pic);
      } else {
        document.body.style.overflow = "";
        this.signShow = false;
      }
    },
    saveData() {
      parentConfirm({ app_id: this.appid, sign_pdf: this.sign_pdf }).then((res) => {
        if (res.code == 0) {
          Toast(res.msg);
          this.getInit();
          document.body.style.overflow = "";
          this.signShow = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.fontBold {
  font-weight: bold;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.important {
  color: #d9534f;
}

.redColor {
  color: #ee0a24;
}
.colorBlue {
  color: #4d88d2;
}
.colorGreen {
  color: #45a367;
}
.img28 {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}

.align_items {
  align-items: center;
}

.childDetail {
  align-items: center;
  padding: 16px;
  background: #fff;

  .img44 {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.contact {
  padding: 0 0 0 16px !important;
  margin: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.appForms {
  margin: 16px;
  background: #fff;
  padding: 16px;

  .requiredIcon {
    align-items: center;
    font-weight: 500;
  }

  .selectCSs {
    padding: 12px 0;
    border-bottom: 1px solid #ebedf0;
    font-size: 14px;
    color: #333;
  }

  .inputCSs {
    border: 1px solid #ebedf0;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
  }

  .radioItems {
    margin-bottom: 12px;
    padding: 12px;
    border: 1px solid #ebedf0;
    border-radius: 8px;
    align-items: start;
  }
}

.submitBtn {
  padding: 5px 16px;
  background: #fff;
}

.applicationInfo {
  padding: 0 16px 16px;
  background: #fff;
  position: relative;
  // margin: 16px;

  .lineHeight {
    line-height: 1;
  }

  .dropoutConfirm {
    position: absolute;
    right: 0;
    top:-80px;
    width: 97px;
    height: 97px;
    z-index: 99;
  }

  .width16 {
    width: 16px;

    .itemsBg {
      width: 6px;
      height: 16px;
      background: #4d88d2;
      border-radius: 3px;
      display: inline-block;
    }
  }

  .mt-8 {
    margin-top: 8px;
  }

  .memo {
    padding: 12px;
    background: #f7f8fa;
    border-radius: 4px;
    font-size: 13px;
    color: #666;
  }

  .refundInfo {
    height: 86px;
    background: linear-gradient(131deg, #dee8ff 0%, #eff5ff 100%);
    border-radius: 12px;
    margin-top: 24px;
    position: relative;
    display: flex;
    .flex1 {
      flex: 1;
    }
    .refund {
      background: #476bf5;
      border-radius: 0px 199px 199px 0px;
      left: 0;
      margin-top: 12px;
      color: #fff;
      font-size: 14px;
      padding: 5px 12px;
      height: 14px;
    }

    .amount {
      font-size: 20px;
      padding-left: 16px;
      padding-top: 13px;
      font-weight: 600;
    }

    img {
      height: 100%;
    }
  }

  .refundItems {
    padding-top: 16px;
    align-items: center;
  }

  .bto {
    border-bottom: 1px solid #ebedf0;
    padding-bottom: 16px;
  }

  .tips {
    background: #fdf8f1;
    padding: 13px 16px;

    .colorYellow {
      color: #f5a429;
    }

    .icon {
      padding-top: 2px;
    }
  }
  .send_memo {
    padding: 16px;
    color: #333;
    font-size: 14px;
    background: #f7f8fa;
    border-radius: 4px;
    margin-bottom: 24px;
  }
  .subTitle {
    font-size: 15px;
    font-weight: 500;
    color: #4d88d2;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      margin-right: 6px;
    }
  }
  .titleAmount {
    background: #f7f8fa;
    border-radius: 12px;
    font-size: 14px;
    color: #323233;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    padding: 2px 0;
  }
}
</style>
